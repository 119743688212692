import React, { useEffect, useState } from 'react';
import EditRoundFlavorStyle from './EditRoundFlavor.module.scss';
import {
  Label,
  CardRow,
  DropdownField,
  Button,
  TextField,
  Checkbox,
  CardColumn,
  ListFlavorsModal,
  Spinner,
} from '../../../components';
import Previews from './Preview.jsx';
import RoundService from '../../services/rounds/round.service';
import BrandService from '../../services/brands/brand.service';
import ThresholdService from '../../services/threshold/threshold.service';
import { useTranslation } from 'react-i18next';
import RoundAppService from '../../services/rounds/roundApp.service';
import editPermission from '../../../assets/editPermission.svg';
import { X } from 'react-feather';
import ReactTooltip from 'react-tooltip';

const EditRoundFlavor = props => {
  const { t } = useTranslation();
  const roundService = new RoundService();
  const roundAppService = new RoundAppService();
  const brandService = new BrandService();
  const thresholdService = new ThresholdService();
  const [brands, setBrands] = useState([]);
  const [brandsValue, setBrandsValue] = useState({
    threshold_batch_code: props.round.threshold_batch_code,
    uuid_brand: props.round.uuid_brand,
  });
  const [users, setUsers] = useState([]);
  const [Round, setRound] = useState(props.round);
  const [flavors, setFlavors] = useState([]);
  const [colors, setColors] = useState([]);
  const [Supplier, setSupplier] = useState([]);
  const [unit, setUnit] = useState([]);
  const [flavorCardArr, setFlavorCardArr] = useState([]);
  const [flavorCard, setFlavorCard] = useState({
    uuid_identification: null,
    uuid_round: props.round.uuid_round,
    uuid_flavor: null,
    uuid_supplier: null,
    threshold: null,
    concentration: null,
    uuid_unit: null,
    batch_code: null,
  });
  const [PreviewsShow, setPreviewsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [listFlavors, setListFlavors] = useState([]);
  const [showFlavorModal, setShowFlavorModal] = useState(false);

  useEffect(() => {
    refreshState();
  }, []);

  useEffect(() => {
    props.setAllow(
      props.round.bol_tvs
        ? !flavorCardArr.every(item => item.uuid_color)
        : !flavorCardArr.every(item => item.uuid_flavor && item.uuid_supplier),
    );
    props.setRound(Round);
  }, [
    props.round.bol_tvs
      ? flavorCardArr.every(item => item.uuid_color)
      : flavorCardArr.every(item => item.uuid_flavor && item.uuid_supplier),
  ]);

  const refreshState = () => {
    setTemplateName(props.round.str_template_name);
    brandService.listBrands().then(res => {
      if (res.type === 'success' && res.results) {
        setBrands(res.results);
      } else {
        window.alert(res.message);
      }
    });

    roundAppService.FlavorCardList(props.round.uuid_round).then(res => {
      if (res.type === 'success' && res.results) {
        setFlavorCardArr(res.results);
      } else {
        window.alert(res.message);
      }
    });

    thresholdService.listColors({}).then(res => {
      if (res.type === 'success' && res.results) {
        setColors(res.results);
      } else {
        window.alert(res.message);
      }
    });

    thresholdService
      .listSuppliers({ uuid_round_type: props.round.uuid_round_type })
      .then(res => {
        if (res.type === 'success' && res.results) {
          setSupplier(res.results);
        } else {
          window.alert(res.message);
        }
      });

    thresholdService.listUnits().then(res => {
      if (res.type === 'success' && res.results) {
        setUnit(res.results);
      } else {
        window.alert(res.message);
      }
    });

    roundService.listRoundUsers(props.round.uuid_round).then(res => {
      if (res.type === 'success' && res.results) {
        setUsers(res.results);
      } else {
        window.alert(res.message);
      }
    });
    getRoundListFlavors();
  };

  const getRoundListFlavors = () => {
    const { uuid_round, uuid_site } = props.round;
    roundService
      .getRoundListFlavors({
        uuid_round,
        uuid_site,
      })
      .then(res => {
        if (res.type === 'success' && res.results) {
          setListFlavors(res.results);
          const listFlavors = res.results.filter(item => {
            return item.bol_flavor_status;
          });
          setFlavors(listFlavors);
        } else {
          window.alert(res.message);
        }
      });
  };
  const addCard = () => {
    setLoading(true);
    roundService
      .addFlavorCard({ uuid_round: props.round.uuid_round })
      .then(res => {
        if (res.type === 'success' && res.results) {
          setFlavorCard({
            ...flavorCard,
            uuid_identification:
              res.results[0].round_sp_in_up_admin_flavors_identification,
          });
          setFlavorCardArr([
            ...flavorCardArr,
            {
              ...flavorCard,
              uuid_identification:
                res.results[0].round_sp_in_up_admin_flavors_identification,
            },
          ]);
          setLoading(false);
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      });
  };

  const del = id => {
    const arr = JSON.parse(JSON.stringify(flavorCardArr));
    const ress = arr.filter(item => {
      return item.uuid_identification !== id;
    });
    roundService.deleteFlavorCard(id).then(res => {
      if (res.type === 'success' && res.results) {
        setFlavorCardArr(ress);
      } else {
        window.alert(res.message);
      }
    });
  };

  const handleChangeConcentration = evt => {
    const num = evt.target.value;
    const name = evt.target.name;
    const data = { [name]: num };
    setBrandsValue(prevState => {
      return { ...prevState, ...data };
    });
    setRound(prevState => {
      return { ...prevState, ...data };
    });
    props.setRound(prevState => {
      return { ...prevState, ...data };
    });
    let obj = {
      ...brandsValue,
      ...data,
    };
    roundService.updateRound({ ...Round, ...obj }).then(res => {
      if (res.type === 'success' && res.results) {
        thresholdService
          .listSets({ uuid_round: props.round.uuid_round })
          .then(res => {
            if (res.type === 'success' && res.results) {
            } else {
              window.alert(res.message);
            }
          });
      }
    });
  };

  const handleChangeFilter = (evt, id) => {
    const value = evt.target.value;
    const name = evt.target.name;
    var flavor = JSON.parse(JSON.stringify(flavorCardArr));
    const obj = flavor.filter(item => {
      if (item.uuid_identification === id) {
        if (!value) {
          item[name] = null;
        } else {
          item[name] = value;
        }
      }
      return item.uuid_identification === id;
    })[0];

    if (name === 'uuid_flavor' || name === 'threshold') {
      if (obj.uuid_flavor) {
        const element = flavors.filter(item => {
          return item.uuid_flavor === obj.uuid_flavor;
        })[0];
        obj.str_name_unit = element.str_unity;
        obj.uuid_unit = element.uuid_unity;
        if (element.flt_concentration) {
          const singleValue = element.flt_concentration / element.flt_threshold;
          obj.concentration = obj.threshold * singleValue;
        }
        if (obj.concentration) {
          obj.concentration = (obj.concentration - 0).toFixed(3);
        }
        if (obj.threshold) {
          obj.threshold = obj.threshold - 0;
        }
      }
    }

    roundService.addFlavorCard(obj).then(res => {
      if (res.type === 'success' && res.results) {
      } else {
        window.alert(res.message);
      }
    });
    setFlavorCardArr(flavor);
  };

  const preview = () => {
    setPreviewsShow(true);
  };

  const updateRound = round => {
    roundService.updateRound(round).then(res => {
      if (res.type === 'success' && res.results) {
        props.setRound({
          ...round,
          arr_samples: props.round.arr_samples,
        });
        setRound(round);
      } else {
        window.alert(res.message);
      }
    });
  };

  const handleCheckTemplate = sample => {
    if (sample.target) {
      var round = JSON.parse(JSON.stringify(Round));
      round.bol_template = sample.target.value;
      updateRound(round);
    }
  };

  const handleTemplateName = e => {
    var round = JSON.parse(JSON.stringify(Round));
    round.str_template_name = e.target.value;
    updateRound(round);
  };

  const handleChangeFlavors = async (item, index) => {
    const { uuid_round, uuid_site } = props.round;
    const { uuid_flavor, bol_flavor_status } = item;
    const res = await roundService.updateListFlavors({
      uuid_round,
      uuid_site,
      uuid_flavor,
      bol_del: bol_flavor_status,
    });
    if (res) {
      getRoundListFlavors();
    }
  };

  const handleSelectAll = async (arr_uuid_flavors, bol_del) => {
    setLoading(true);
    const { uuid_round, uuid_site } = props.round;
    const res = await roundService.updateAllFlavors({
      uuid_round,
      uuid_site,
      bol_del,
      arr_uuid_flavors,
    });
    if (res) {
      getRoundListFlavors();
    }
    setLoading(false);
  };

  return (
    <div className={EditRoundFlavorStyle.flavor}>
      {PreviewsShow && (
        <Previews
          round={props.round}
          setPreviewsShow={setPreviewsShow}
        ></Previews>
      )}
      <CardRow
        style={{
          margin: '12px 20px 10px 20px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Label
          bold
          style={{ color: '#919191', fontSize: '22px' }}
          label={t('flavor_identification')}
        ></Label>
        <div className={EditRoundFlavorStyle.column_optionsample}>
          <Button
            className={
              typeof props.round.arr_status === 'object' &&
                props.round.arr_status.length >= 4 &&
                props.round.arr_status[3] === 'IN_PROGRESS' &&
                !props.round.bol_tvs
                ? EditRoundFlavorStyle.column_options_disabled
                : EditRoundFlavorStyle.column_options
            }
            label={
              t('configure_available_flavors') + ' ' + `(${flavors.length})`
            }
            disabled={
              typeof props.round.arr_status === 'object' &&
              props.round.arr_status.length >= 4 &&
              props.round.arr_status[3] === 'IN_PROGRESS' &&
              !props.round.bol_tvs
            }
            onClick={() => {
              setShowFlavorModal(true);
            }}
          >
            <img src={editPermission} className={EditRoundFlavorStyle.imgDom} />
          </Button>
        </div>
      </CardRow>
      <CardRow
        style={{
          alignItems: 'center',
          marginBottom: '20px',
          flexWrap: 'wrap',
        }}
      >
        <ul className={EditRoundFlavorStyle.flavorHeader}>
          <li className={EditRoundFlavorStyle.flavorBrand}>
            <DropdownField
              style={{ width: '100%', minWidth: '200px' }}
              labelStyle={{
                fontWeight: 'bold',
                fontSize: '16px',
              }}
              name="uuid_brand"
              options={brands}
              valueField="uuid_brand"
              disabled={
                typeof props.round.arr_status === 'object' &&
                props.round.arr_status.length >= 4 &&
                props.round.arr_status[3] === 'IN_PROGRESS'
              }
              labelField="str_name"
              placeholder={t('list_of_beers')}
              label={t('threshold_select_base_beer') + ' *'}
              value={brandsValue.uuid_brand}
              onChange={handleChangeConcentration}
            />
          </li>
          <li className={EditRoundFlavorStyle.flavor_beer_batch}>
            <TextField
              labelStyle={{
                fontWeight: 'bold',
                fontSize: '16px',
              }}
              style={{ width: '100%', minWidth: '200px' }}
              disabled={
                typeof props.round.arr_status === 'object' &&
                props.round.arr_status.length >= 4 &&
                props.round.arr_status[3] === 'IN_PROGRESS'
              }
              name="threshold_batch_code"
              label={t('beer_batch')}
              value={brandsValue.threshold_batch_code}
              onChange={handleChangeConcentration}
            />
          </li>
          <li className={EditRoundFlavorStyle.flavor_bol_template}>
            <Checkbox
              name="bol_template"
              value={
                props.round.bol_template === 'Template' ||
                  props.round.bol_template === 'Yes' ||
                  props.round.bol_template === 1
                  ? true
                  : false
              }
              onChange={evt => {
                handleCheckTemplate(evt);
              }}
            />
            <TextField
              disabled={
                !props.round.bol_template || props.round.bol_template === 'No'
              }
              placeholder={t('common_name_template')}
              style={{ minHeight: '40px' }}
              labelStyle={{
                marginLeft: '10px',
                fontWeight: 'bold',
                fontSize: '16px',
              }}
              label={t('common_template')}
              value={templateName}
              onBlur={e => {
                handleTemplateName(e);
              }}
              onChange={e => {
                setTemplateName(e.target.value);
              }}
            ></TextField>
          </li>
          <li className={EditRoundFlavorStyle.flavor_preview}>
            <Button
              style={
                flavorCardArr.length > 0 &&
                  (props.round.bol_tvs
                    ? flavorCardArr.every(item => item.uuid_color)
                    : flavorCardArr.every(
                      item => item.uuid_flavor && item.uuid_supplier,
                    )) &&
                  users.length != 0
                  ? {
                    minHeight: '42px',
                    marginTop: '10px',
                    width: '128px',
                    color: '#fff',
                    background: '#0A74FF',
                  }
                  : {
                    minHeight: '42px',
                    marginTop: '10px',
                    width: '128px',
                    color: '#fff',
                  }
              }
              label={t('preview')}
              onClick={() => preview()}
              disabled={
                flavorCardArr.length === 0 ||
                (props.round.bol_tvs
                  ? !flavorCardArr.every(item => item.uuid_color)
                  : !flavorCardArr.every(
                    item => item.uuid_flavor && item.uuid_supplier,
                  )) ||
                users.length === 0
              }
            />
          </li>
        </ul >
      </CardRow >
      {
        flavorCardArr.length > 0 &&
        flavorCardArr.map((item, index) => {
          return (
            <CardRow
              key={item.uuid_identification}
              style={{
                display: 'flex',
                marginLeft: '20px',
                alignItems: 'center',
                marginBottom: '30px',
              }}
            >
              <div
                className={
                  EditRoundFlavorStyle.add + ' ' + EditRoundFlavorStyle.num
                }
              >
                {index + 1}
              </div>
              <CardColumn
                style={{
                  marginLeft: '30px',
                  maxWidth: '1550px',
                  minHeight: '250px',
                  borderRadius: '5px',
                  border: '1px #E8E8E8 solid',
                  position: 'relative',
                }}
              >
                <Button
                  disabled={
                    typeof props.round.arr_status === 'object' &&
                    props.round.arr_status.length >= 4 &&
                    props.round.arr_status[3] === 'IN_PROGRESS'
                  }
                  round
                  remove
                  className={EditRoundFlavorStyle.del}
                  onClick={() => {
                    del(item.uuid_identification)
                  }}
                  dataTip={t('remove_this_sample')}
                >
                  <X width='16px' />
                </Button>
                <ReactTooltip backgroundColor='#F0AD4E' effect="solid" place="top" />
                <CardRow
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {props.round.bol_tvs ? (
                    <CardColumn
                      style={{
                        flex: '1',
                        fontSize: '14px',
                        color: '#636363',
                      }}
                    >
                      <DropdownField
                        labelStyle={{
                          fontWeight: 'bold',
                          fontSize: '16px',
                        }}
                        options={colors}
                        name="uuid_color"
                        valueField="uuid_color"
                        labelField="str_name_color"
                        value={item.uuid_color}
                        placeholder={t('select_the_color')}
                        label={t('Color') + ' *'}
                        id={item.uuid_identification}
                        onChange={handleChangeFilter}
                      />
                    </CardColumn>
                  ) : null}
                  <CardColumn
                    style={{
                      flex: '1',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <DropdownField
                      labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                      options={listFlavors.filter(el => el.bol_flavor_status)}
                      name="uuid_flavor"
                      valueField="uuid_flavor"
                      labelField="str_name"
                      value={item.uuid_flavor}
                      placeholder={t('select_the_flavor')}
                      label={
                        props.round.bol_tvs
                          ? t('common_flavor')
                          : t('common_flavor') + ' *'
                      }
                      id={item.uuid_identification}
                      onChange={handleChangeFilter}
                    />
                  </CardColumn>
                  <CardColumn
                    style={{
                      flex: '1',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <DropdownField
                      labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                      options={Supplier}
                      name="uuid_supplier"
                      valueField="uuid_supplier"
                      labelField="str_name"
                      label={
                        props.round.bol_tvs
                          ? t('common_supplier')
                          : t('common_supplier') + ' *'
                      }
                      value={item.uuid_supplier}
                      id={item.uuid_identification}
                      onChange={handleChangeFilter}
                    />
                  </CardColumn>
                  <CardColumn
                    style={{
                      flex: '1',
                      paddingTop: '18px',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <TextField
                      labelStyle={{
                        marginLeft: '10px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                      style={{ margin: '10px' }}
                      name="batch_code"
                      label={t('common_batch_code')}
                      value={item.batch_code}
                      onChange={handleChangeFilter}
                      id={item.uuid_identification}
                    />
                  </CardColumn>
                </CardRow>
                <CardRow
                  style={{
                    display: 'flex',
                    marginTop: '10px',
                    flexWrap: 'wrap',
                  }}
                >
                  <CardColumn
                    style={{
                      flex: '1',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <DropdownField
                      labelStyle={{
                        fontSize: '16px',
                        color: '#636363',
                        fontWeight: 'bold',
                      }}
                      options={[
                        { str_name: 0.5 },
                        { str_name: 1 },
                        { str_name: 1.5 },
                        { str_name: 2 },
                        { str_name: 2.5 },
                        { str_name: 3 },
                        { str_name: 3.5 },
                        { str_name: 4 },
                        { str_name: 4.5 },
                        { str_name: 5 },
                        { str_name: 5.5 },
                        { str_name: 6 },
                      ]}
                      name="threshold"
                      labelField="str_name"
                      valueField="str_name"
                      label={t('flavor_threshold')}
                      value={item.threshold}
                      id={item.uuid_identification}
                      onChange={handleChangeFilter}
                    />
                  </CardColumn>
                  <CardColumn
                    style={{
                      flex: '1',
                      paddingTop: '18px',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <TextField
                      labelStyle={{
                        marginLeft: '10px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                      style={{ margin: '10px' }}
                      name="concentration"
                      label={t('concentration')}
                      number
                      value={item.concentration}
                      placeholder={t('input_digital_number')}
                      onChange={handleChangeFilter}
                      id={item.uuid_identification}
                    />
                  </CardColumn>
                  <CardColumn
                    style={{
                      flex: '1',
                      fontSize: '14px',
                      color: '#636363',
                    }}
                  >
                    <DropdownField
                      labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                      options={unit}
                      name="uuid_unit"
                      valueField="uuid_unity"
                      labelField="str_name"
                      label={t('common_unit')}
                      value={item.uuid_unit}
                      id={item.uuid_identification}
                      onChange={handleChangeFilter}
                    />
                  </CardColumn>
                </CardRow>
                {
                  typeof props.round.arr_status === 'object' &&
                  props.round.bol_tvs === false &&
                  props.round.arr_status.length >= 4 &&
                  props.round.arr_status[3] === 'IN_PROGRESS' && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(239, 239, 239, 0.3)',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        cursor: 'not-allowed',
                      }}
                    ></div>
                  )
                }
              </CardColumn>
            </CardRow>
          );
        })
      }
      < CardRow style={{ margin: '0px 0 20px 100px' }}>
        {
          flavorCardArr.length >= 30
            ? null
            : (
              !loading ? (
                <div
                  className={EditRoundFlavorStyle.addCardBox}
                  style={
                    users.length === 0 ||
                      (typeof props.round.arr_status === 'object' &&
                        props.round.arr_status.length >= 4 &&
                        props.round.arr_status[3] === 'IN_PROGRESS') ||
                      !brandsValue.uuid_brand ||
                      loading
                      ? {
                        cursor: 'not-allowed',
                      }
                      : null
                  }
                  onClick={() =>
                    users.length === 0 ||
                      (typeof props.round.arr_status === 'object' &&
                        props.round.arr_status.length >= 4 &&
                        props.round.arr_status[3] === 'IN_PROGRESS') ||
                      !brandsValue.uuid_brand ||
                      loading
                      ? null
                      : addCard()
                  }>
                  <div
                    className={EditRoundFlavorStyle.addCard}
                    style={
                      users.length === 0 ||
                        (typeof props.round.arr_status === 'object' &&
                          props.round.arr_status.length >= 4 &&
                          props.round.arr_status[3] === 'IN_PROGRESS') ||
                        !brandsValue.uuid_brand ||
                        loading
                        ? {
                          color: '#CCCCCC',
                          border: '2px solid #CCCCCC',
                        }
                        : null
                    }
                  >
                    +
                  </div>
                  <span className={EditRoundFlavorStyle.addCardText}
                    style={
                      users.length === 0 ||
                        (typeof props.round.arr_status === 'object' &&
                          props.round.arr_status.length >= 4 &&
                          props.round.arr_status[3] === 'IN_PROGRESS') ||
                        !brandsValue.uuid_brand ||
                        loading
                        ? {
                          color: '#CCCCCC',
                        }
                        : null
                    }>
                    {t('add_a_flavor')}
                  </span>
                </div>
              )
                : < Spinner />
            )
        }
      </CardRow >
      {showFlavorModal && listFlavors.length > 0 && (
        <ListFlavorsModal
          listFlavors={listFlavors}
          handleChange={(item, index) => {
            handleChangeFlavors(item, index);
          }}
          closeModal={() => {
            setShowFlavorModal(false);
          }}
          checkbox={true}
          setListFlavors={setListFlavors}
          handleSelectAll={handleSelectAll}
          loading={loading}
        />
      )}
    </div >
  );
};

export default EditRoundFlavor;

import ListFlavorsStyle from './ListFlavors.module.scss';
import { CardRow, Button, CardColumn } from '../../components';
import configs from '../../configs';
import nullFlavor from '../../assets/Null_Flavor.png';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ListFlavorsModal = props => {
  const { t } = useTranslation();
  const [searchArr, setSearchArr] = useState(props.listFlavors);

  const handleSearchData = str => {
    setSearchArr(
      props.listFlavors.filter(el => {
        const find = searchArr.find(v => v.uuid_flavor === el.uuid_flavor);
        el.bol_flavor_status = find
          ? find.bol_flavor_status
          : el.bol_flavor_status;
        return el.str_name.toLowerCase().indexOf(str.toLowerCase()) > -1;
      }),
    );
  };

  return (
    <div className={ListFlavorsStyle.ListFlavorsModal}>
      <CardColumn className={ListFlavorsStyle.maskCardRow}>
        <CardRow itemAlign={'center'}>
          <CardColumn transparent className={ListFlavorsStyle.searchDiv}>
            <CardRow transparent>
              <input
                className={ListFlavorsStyle.searchInput}
                placeholder={t('Search')}
                type="text"
                onChange={e => {
                  const str = e.target.value;
                  handleSearchData(str);
                }}
              />
              <Button
                loading={props.loading}
                style={
                  props.loading
                    ? { marginLeft: '18px', cursor: 'not-allowed' }
                    : { marginLeft: '18px' }
                }
                blue
                label={t('select_all_flavor')}
                onClick={() => {
                  props.handleSelectAll(
                    searchArr.map(el => ({
                      ...el,
                      bol_flavor_status: false,
                    })),
                    false,
                  );
                  setSearchArr(prev =>
                    prev.map(el => ({
                      ...el,
                      bol_flavor_status: true,
                    })),
                  );
                }}
              />
              <Button
                loading={props.loading}
                style={
                  props.loading
                    ? { marginLeft: '18px', cursor: 'not-allowed' }
                    : { marginLeft: '18px' }
                }
                confirm
                label={t('deselect_all')}
                onClick={() => {
                  props.handleSelectAll(
                    searchArr.map(el => ({
                      ...el,
                      bol_flavor_status: true,
                    })),
                    true,
                  );
                  setSearchArr(prev =>
                    prev.map(el => ({
                      ...el,
                      bol_flavor_status: false,
                    })),
                  );
                }}
              />
            </CardRow>
          </CardColumn>
          <div
            className={'iconfont ' + ListFlavorsStyle.off}
            onClick={() => props.closeModal()}
          >
            &#xe61d;
          </div>
        </CardRow>
        <ul className={ListFlavorsStyle.ul}>
          {searchArr?.map((item, index) => {
            return (
              <li
                key={index}
                className={ListFlavorsStyle.flaovrlist}
                onClick={() => {
                  props.handleChange(item, index);
                  setSearchArr(prev =>
                    prev.map((el, i) => ({
                      ...el,
                      bol_flavor_status:
                        index === i
                          ? !el.bol_flavor_status
                          : el.bol_flavor_status,
                    })),
                  );
                }}
              >
                {props.checkbox && (
                  <div>
                    <input type="checkbox" checked={item.bol_flavor_status} />
                  </div>
                )}
                <img
                  src={
                    item.str_image
                      ? `${configs.file_api_url}/v1/flavors/${item.str_image}`
                      : nullFlavor
                  }
                  className={ListFlavorsStyle.img}
                />
                <p style={{ marginLeft: '15px' }}>{item.str_name}</p>
              </li>
            );
          })}
        </ul>
      </CardColumn>
    </div>
  );
};

export default ListFlavorsModal;

